import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import cx from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Heading from "../Elements/Heading"
import Breadcrumbs from "../Breadcrumbs"
import { formatCleanPhone } from "../../helpers/format"

import {
  AddressIcon,
  EmailIcon,
  FBPurpleIcon,
  InstagramIcon,
  OfficeImage,
  PhoneIcon,
  TelegramPurpleIcon,
  VKPurpleIcon,
  YouTubePurpleIcon,
} from "../../assets/images"
import * as styles from "./contactsBlock.module.css"

const ContactsBlock = ({ pageContext }) => {
  const { t } = useTranslation()
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          allSettings {
            themeSettingsVkLink
            themeSettingsPhone
            themeSettingsMapLink
            themeSettingsInstagramLink
            themeSettingsFacebookLink
            themeSettingsTelegramLink
            themeSettingsYoutubeLink
            themeSettingsEmail
            themeSettingsEmailSales
            themeSettingsAddress
          }
        }
      }
    `
  )
  const {
    themeSettingsPhone: phone,
    themeSettingsEmail: email,
    themeSettingsEmailSales: emailSales,
    themeSettingsAddress: address,
    themeSettingsVkLink: vkLink,
    themeSettingsFacebookLink: facebookLink,
    themeSettingsInstagramLink: instagramLink,
    themeSettingsTelegramLink: telegramLink,
    themeSettingsYoutubeLink: youTubeLink,
    themeSettingsMapLink: mapLink,
  } = wp.allSettings

  return (
    <>
      <div className={styles.container}>
        <div className={cx(styles.inner, "page_titles")}>
          <Breadcrumbs pageContext={pageContext} />
          <Heading pageTitle>{t("Контакты")}</Heading>
        </div>
        <div className={styles.tabsOffice}>
          <div className={styles.tabOffice}>{t("Офис в Москве")}</div>
        </div>
        <div className={styles.contactsTab}>
          {email && (
            <a href={`mailto:${email}`} className={styles.contactLink}>
              <span className={styles.contactLinkImg}>
                <img className={styles.contactImg} src={EmailIcon} alt="" />
              </span>
              <span className={styles.contactText}>{email}</span>
            </a>
          )}
          {emailSales && (
            <a href={`mailto:${emailSales}`} className={styles.contactLink}>
              <span className={styles.contactLinkImg}>
                <img className={styles.contactImg} src={EmailIcon} alt="" />
              </span>
              <span className={styles.contactText}>{emailSales}</span>
            </a>
          )}
          {phone && (
            <a
              href={`tel:${formatCleanPhone(phone)}`}
              className={styles.contactLink}
            >
              <span className={styles.contactLinkImg}>
                <img className={styles.contactImg} src={PhoneIcon} alt="" />
              </span>
              <span className={styles.contactText}>{phone}</span>
            </a>
          )}
          {address && (
            <span className={styles.contactLink}>
              <span className={styles.contactLinkImg}>
                <img className={styles.contactImg} src={AddressIcon} alt="" />
              </span>
              <span className={styles.contactText}>{address}</span>
            </span>
          )}
          {(instagramLink ||
            facebookLink ||
            vkLink ||
            telegramLink ||
            youTubeLink) && (
            <div className={styles.socialBlock}>
              {instagramLink && (
                <a
                  href={instagramLink}
                  rel="noreferrer"
                  target="_blank"
                  className={styles.socialItem}
                >
                  <img
                    className={styles.socialIcon}
                    src={InstagramIcon}
                    alt=""
                  />
                </a>
              )}
              {facebookLink && (
                <a
                  href={facebookLink}
                  rel="noreferrer"
                  target="_blank"
                  className={styles.socialItem}
                >
                  <img
                    className={styles.socialIcon}
                    src={FBPurpleIcon}
                    alt=""
                  />
                </a>
              )}
              {telegramLink && (
                <a
                  href={telegramLink}
                  rel="noreferrer"
                  target="_blank"
                  className={styles.socialItem}
                >
                  <img
                    className={styles.socialIcon}
                    src={TelegramPurpleIcon}
                    alt=""
                  />
                </a>
              )}
              {vkLink && (
                <a
                  href={vkLink}
                  rel="noreferrer"
                  target="_blank"
                  className={styles.socialItem}
                >
                  <img
                    className={styles.socialIcon}
                    src={VKPurpleIcon}
                    alt=""
                  />
                </a>
              )}
              {youTubeLink && (
                <a
                  href={youTubeLink}
                  rel="noreferrer"
                  target="_blank"
                  className={styles.socialItem}
                >
                  <img
                    className={styles.socialIcon}
                    src={YouTubePurpleIcon}
                    alt=""
                  />
                </a>
              )}
            </div>
          )}
        </div>
        <div className={styles.contactsImage}>
          <img src={OfficeImage} alt={t("DVGroup Москва")} />
        </div>
      </div>
      <iframe
        title="Map"
        className={styles.map}
        src={mapLink}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </>
  )
}

export default ContactsBlock

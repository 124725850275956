// extracted by mini-css-extract-plugin
export var contactImg = "contactsBlock-module--contactImg--b8532";
export var contactLink = "contactsBlock-module--contactLink--eac2c";
export var contactLinkImg = "contactsBlock-module--contactLinkImg--b23ed";
export var contactText = "contactsBlock-module--contactText--29990";
export var contactsImage = "contactsBlock-module--contactsImage--973d2";
export var contactsTab = "contactsBlock-module--contactsTab--c0d3b";
export var container = "contactsBlock-module--container--df01a";
export var inner = "contactsBlock-module--inner--f89f5";
export var map = "contactsBlock-module--map--1f45d";
export var socialBlock = "contactsBlock-module--socialBlock--b9e2f";
export var socialIcon = "contactsBlock-module--socialIcon--f9bfb";
export var socialItem = "contactsBlock-module--socialItem--9503a";
export var tabOffice = "contactsBlock-module--tabOffice--39161";
export var tabsOffice = "contactsBlock-module--tabsOffice--30e06";